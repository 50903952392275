export const FETCH_DATASOURCE_DETAIL = "FETCH_DATASOURCE_DETAIL";
export const FETCH_CREDENTIALS = "FETCH_CREDENTIALS";
export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_LLM_CREDENTIALS = "FETCH_LLM_CREDENTIALS";
export const DATAWAREHOUSES_LIST_LOADED = "DATAWAREHOUSES_LIST_LOADED";
export const FETCH_CONFIGURED_REPORTS = "FETCH_CONFIGURED_REPORTS";
export const FETCH_TEMPLATES = "FETCH_TEMPLATES";
export const SET_TEMPLATE_IS_LOADING = "SET_TEMPLATE_IS_LOADING";
export const SET_DATASOURCE_IS_LOADING = "SET_DATASOURCE_IS_LOADING";
export const SET_CREDENATIALS_IS_LOADING = "SET_CREDENATIALS_IS_LOADING";
export const SET_CONFIGURED_REPORTS_IS_LOADING = "SET_CONFIGURED_REPORTS_IS_LOADING";
export const SET_REPORTS_IS_LOADING = "SET_REPORTS_IS_LOADING";
export const FETCH_LLM_CREDENTIALS_IS_LOADING = "FETCH_LLM_CREDENTIALS_IS_LOADING";

export const DATASOURCE_LIST_LOADED = "DATASOURCE_LIST_LOADED";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const FETCH_EDIT_LOGS = "FETCH_EDIT_LOGS";
export const SET_EDIT_LOGS_IS_LOADING = "SET_EDIT_LOGS_IS_LOADING";

export const SET_TOKENS = "SET_TOKENS";

//transformations
export const TRANSFORMATIONS_LIST_LOADED = "TRANSFORMATIONS_LIST_LOADED";
export const SET_TRANSFORMATIONS_IS_LOADING = "SET_TRANSFORMATIONS_IS_LOADING";
export const TRANSFORMATIONS_LOGS_LOADED = "TRANSFORMATIONS_LOGS_LOADED";
export const SET_TRANSFORMATIONS_LOGS_IS_LOADING = "SET_TRANSFORMATIONS_LOGS_IS_LOADING";

//users
export const USER_LIST_LOADED = "USER_LIST_LOADED";
export const SET_USER_IS_LOADING = "SET_USER_IS_LOADING";
export const SET_USER = "SET_USER";
export const SET_ACCOUNT_TIMEZONE = "SET_ACCOUNT_TIMEZONE";
export const SET_CREDENTIALS = "SET_CREDENTIALS";
export const SET_CREDENTIALS_IS_LOADING = "SET_CREDENTIALS_IS_LOADING";
export const SET_SELECTED_DATASOURCE = "SET_SELECTED_DATASOURCE";
export const ME_USER_IS_LOADING = "ME_USER_IS_LOADING";
export const SET_DATA_USAGE_DATEWISE = "SET_DATA_USAGE_DATEWISE";
export const SET_DATA_USAGE_DATEWISE_IS_LOADING = "SET_DATA_USAGE_DATEWISE_IS_LOADING";
export const SET_LLM_CREDENTIALS = "SET_LLM_CREDENTIALS";
export const SET_LLM_CREDENTIALS_IS_LOADING = "SET_LLM_CREDENTIALS_IS_LOADING";

//dashboard

export const FETCH_CONFIGURED_REPORTS_LIST = "FETCH_CONFIGURED_REPORTS_LIST";
// export const SET_DASHBOARD_IS_LOADING = "SET_DASHBOARD_IS_LOADING"
export const SET_CONFIGURED_REPORTS_LIST_IS_LOADING = "SET_CONFIGURED_REPORTS_LIST_IS_LOADING";
export const FETCH_DATASOURCES = "FETCH_DATASOURCES";
export const FETCH_OVERALL_STATS = "FETCH_OVERALL_STATS";
export const FETCH_OVERALL_RUN_STATS = "FETCH_OVERALL_RUN_STATS";
export const FETCH_ROWS_MOVED_BY_DATE = "FETCH_ROWS_MOVED_BY_DATE";
export const SET_ROWS_MOVED_BY_DATE_IS_LOADING = "SET_ROWS_MOVED_BY_DATE_IS_LOADING";
export const SET_RECENT_ACTIVITIES_IS_LOADING = "SET_RECENT_ACTIVITIES_IS_LOADING";
export const FETCH_RECENT_ACTIVITIES = "FETCH_RECENT_ACTIVITIES";
export const SET_OVERALL_STATS_IS_LOADING = "SET_OVERALL_STATS_IS_LOADING";
export const SET_OVERALL_RUN_STATS_IS_LOADING = "SET_OVERALL_RUN_STATS_IS_LOADING";
export const SET_DATASOURCES_STATS_IS_LOADING = "SET_DATASOURCES_STATS_IS_LOADING";
export const FETCH_DATA_MOVED_BY_DATE = "FETCH_DATA_MOVED_BY_DATE";
export const SET_DATA_MOVED_BY_DATE_IS_LOADING = "SET_DATA_MOVED_BY_DATE_IS_LOADING";
//configured-reports
export const SET_TABLE_SCHEMA_IS_LOADING = "SET_TABLE_SCHEMA_IS_LOADING";
export const SET_TABLE_SCHEMA = "SET_TABLE_SCHEMA";

//realtime notification
export const SET_COUNT = "SET_COUNT";
export const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST";
export const INCREMENT_COUNT = "INCREMENT_COUNT";
export const SET_NOTIFICATIONS_IS_LOADING = "SET_NOTIFICATIONS_IS_LOADING";

// notification_engines
export const SET_NOTIFICATION_ENGINES = "SET_NOTIFICATION_ENGINES";
export const SET_NOTIFICATION_ENGINES_IS_LOADING = "SET_NOTIFICATION_ENGINES_IS_LOADING";
export const EXTEND_NOTIFICATION_LIST = "EXTEND_NOTIFICATION_LIST";
export const APPEND_NOTIFICATION_LIST = "APPEND_NOTIFICATION_LIST";

//reverse connectors
export const SET_REVERSE_CONNECTORS = "SET_REVERSE_CONNECTORS";
export const SET_REVERSE_CONNECTORS_IS_LOADING = "SET_REVERSE_CONNECTORS_IS_LOADING";
export const SET_REVERSE_CONNECTOR = "SET_REVERSE_CONNECTOR";
export const SET_REVERSE_CONNECTOR_IS_LOADING = "SET_REVERSE_CONNECTOR_IS_LOADING";
export const SET_SYNCS = "SET_SYNCS";
export const SET_SYNCS_IS_LOADING = "SET_SYNCS_IS_LOADING";
export const SET_CONFIGURED_SYNCS = "SET_CONFIGURED_SYNCS";
export const SET_CONFIGURED_SYNCS_IS_LOADING = "SET_CONFIGURED_SYNCS_IS_LOADING";

//data models actions
export const SET_DATA_MODELS = "SET_DATA_MODELS";
export const SET_DATA_MODELS_IS_LOADING = "SET_DATA_MODELS_IS_LOADING";

//configured syncs
export const SET_CHANGE_HISTORY = "SET_CHANGE_HISTORY";
export const SET_CHANGE_HISTORY_IS_LOADING = "SET_CHANGE_HISTORY_IS_LOADING";

// data models logs
export const SET_DATA_MODELS_LOGS = "SET_DATA_MODELS_LOGS";
export const SET_DATA_MODELS_LOGS_IS_LOADING = "SET_DATA_MODELS_LOGS_IS_LOADING";

// new additions
export const SET_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING = "SET_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING";
export const FETCH_REVERSE_ROWS_MOVED_BY_DATE = "FETCH_REVERSE_ROWS_MOVED_BY_DATE";
export const FETCH_DATA_DESTINATIONS = "FETCH_DATA_DESTINATIONS";
export const SET_DATA_DESTINATIONS_STATS_IS_LOADING = "SET_DATA_DESTINATIONS_STATS_IS_LOADING";
export const SET_REVERSE_SYNC_REPORTS_LIST_IS_LOADING = "SET_REVERSE_SYNC_REPORTS_LIST_IS_LOADING";
export const FETCH_REVERSE_SYNC_REPORTS_LIST = "FETCH_REVERSE_SYNC_REPORTS_LIST";

// segments
export const SET_SEGMENTS = "SET_SEGMENTS";
export const SET_SEGMENTS_IS_LOADING = "SET_SEGMENTS_IS_LOADING";

// ui states
export const ADD_GLOBAL_NOTIFICATION = "ADD_GLOBAL_NOTIFICATION";
export const EMPTY_NOTIFICATIONS = "EMPTY_NOTIFICATIONS";
export const MODAL_IS_OPEN = "MODAL_IS_OPEN";
export const TOGGLE_BANNER = "TOGGLE_BANNER";

// homepage states
export const SET_SELECTED_WAREHOUSE = "SET_SELECTED_WAREHOUSE";
export const SET_SELECTED_CREDENTIAL = "SET_SELECTED_CREDENTIAL";
export const UPDATE_DATASOURCE_DATAWAREHOUSE_ID = "UPDATE_DATASOURCE_DATAWAREHOUSE_ID";
export const TOGGLE_SIDENAV = "TOGGLE_SIDENAV";

//banner
export const TOGGLE_SHOW_LOGS_BANNER = "TOGGLE_SHOW_LOGS_BANNER";
//homepage reverse states
export const SET_SELECTED_DATA_MODEL = "SET_SELECTED_DATA_MODEL";
export const SET_SELECTED_DATA_WAREHOUSE = "SET_SELECTED_DATA_WAREHOUSE";
export const SET_SELECTED_TABLE = "SET_SELECTED_TABLE";
export const SET_SELECTED_SEGMENT = "SET_SELECTED_SEGMENT";
export const SET_SELECTED_REVERSE_CRED = "SET_SELECTED_REVERSE_CRED";
export const SET_SELECTED_REVERSE_CONNECTOR = "SET_SELECTED_REVERSE_CONNECTOR";

export const SET_DBT_TRANSFORMATIONS_LOADING = "SET_DBT_TRANSFORMATIONS_LOADING";
export const SET_DBT_TRANSFORMATIONS = "SET_DBT_TRANSFORMATIONS";

export const SET_DBT_CREDENTIALS = "SET_DBT_CREDENTIALS";
export const SET_DBT_CREDENTIALS_LOADING = "SET_DBT_CREDENTIALS_LOADING";
//api keys
export const SET_API_KEYS = "SET_API_KEYS";
export const SET_API_KEYS_LOADING = "SET_API_KEYS_LOADING";
// invoice data
export const SET_INVOICE_DATA = "SET_INVOICE_DATA";
export const INVOICE_DATA_IS_LOADING = "INVOICE_DATA_IS_LOADING";
// announcements
export const LATEST_ANNOUNCEMENTS = "LATEST_ANNOUNCEMENTS";
export const LATEST_ANNOUNCEMENTS_IS_LOADING = "LATEST_ANNOUNCEMENTS_IS_LOADING";
export const ALL_ANNOUNCEMENTS = "ALL_ANNOUNCEMENTS";
export const ALL_ANNOUNCEMENTS_IS_LOADING = "ALL_ANNOUNCEMENTS_IS_LOADING";
export const CLOSE_ANNOUNCEMENT_BANNER = "CLOSE_ANNOUNCEMENT_BANNER";
export const SET_ANNOUNCEMENT_CLOSED_TIME = "SET_ANNOUNCEMENT_CLOSED_TIME";

//orchestrations
export const SET_ORCHESTRATIONS_LIST = "SET_ORCHESTRATIONS_LIST";
export const SET_ORCHESTRATIONS_LOADING = "SET_ORCHESTRATIONS_LOADING";
export const SET_ORCHESTRATION_MODE = "SET_ORCHESTRATION_MODE";
export const SET_ADDING_NODES_TO_CANVAS = "SET_ADDING_NODES_TO_CANVAS";
// onboarding
export const IS_ONBOARDING = "IS_ONBOARDING";
export const SET_ONBOARDING_DATASOURCE = "SET_ONBOARDING_DATASOURCE";
export const SET_ONBOARDING_CREDENTIAL = "SET_ONBOARDING_CREDENTIAL";
export const SET_ONBOARDING_CREDENTIAL_NAME = "SET_ONBOARDING_CREDENTIAL_NAME";
export const SET_ONBOARDING_STEP = "SET_ONBOARDING_STEP";
export const SET_ONBOARDING_WAREHOUSE = "SET_ONBOARDING_WAREHOUSE";
export const TOGGLE_RESTRICTED_FEATURE_POPUP = "TOGGLE_RESTRICTED_FEATURE_POPUP";
export const TOGGLE_UPGRADE_TO_PREMIUM_POPUP = "TOGGLE_UPGRADE_TO_PREMIUM_POPUP";
export const TOGGLE_UPGRADED_TO_PREMIUM_TRIAL = "TOGGLE_UPGRADED_TO_PREMIUM_TRIAL";
export const TOGGLE_RESTRICT_POPUP = "TOGGLE_RESTRICT_POPUP";
// //
export const ONBOARDING_FLOW = "ONBOARDING_FLOW";

// assets
export const SET_SRC = "SET_SRC";
export const SET_SRC_IS_LOADING = "SET_SRC_IS_LOADING";

// dbt cloud
export const SET_DBT_CLOUD_TRANSFORMATIONS = "SET_DBT_CLOUD_TRANSFORMATIONS";
export const SET_DBT_CLOUD_TRANSFORMATIONS_LOADING = "SET_DBT_CLOUD_TRANSFORMATIONS_LOADING";
export const SET_DBT_CLOUD_CREDENTIALS = "SET_DBT_CLOUD_CREDENTIALS";
export const SET_DBT_CLOUD_CREDENTIALS_LOADING = "SET_DBT_CLOUD_CREDENTIALS_LOADING";

// workspaces
export const WORKSPACE_OVERALL_STATS = "WORKSPACE_OVERALL_STATS";
export const WORKSPACE_OVERALL_RUN_STATS = "WORKSPACE_OVERALL_RUN_STATS";
export const WORKSPACE_OVERALL_STATS_IS_LOADING = "WORKSPACE_OVERALL_STATS_IS_LOADING";
export const WORKSPACE_OVERALL_RUN_STATS_IS_LOADING = "WORKSPACE_OVERALL_RUN_STATS_IS_LOADING";
export const WORKSPACE_ACCOUNT_USERS = "WORKSPACE_ACCOUNT_USERS";
export const WORKSPACE_ACCOUNT_USERS_IS_LOADING = "WORKSPACE_ACCOUNT_USERS_IS_LOADING";
export const ACCOUNT_WORKSPACES = "ACCOUNT_WORKSPACES";
export const ACCOUNT_WORKSPACES_IS_LOADING = "ACCOUNT_WORKSPACES_IS_LOADING";
export const ACCOUNT_ROWS_MOVED = "ACCOUNT_ROWS_MOVED";
export const ACCOUNT_ROWS_MOVED_LOADING = "ACCOUNT_ROWS_MOVED_LOADING";
export const WORKSPACE_DATASOURCE_STATS = "WORKSPACE_DATASOURCE_STATS";
export const WORKSPACE_DATASOURCE_STATS_IS_LOADING = "WORKSPACE_DATASOURCE_STATS_IS_LOADING";
export const WORKSPACE_DESTINATION_STATS = "WORKSPACE_DESTINATION_STATS";
export const WORKSPACE_DESTINATION_STATS_IS_LOADING = "WORKSPACE_DESTINATION_STATS_IS_LOADING";
export const WORKSPACE_ROWS_MOVED_BY_DATE = "WORKSPACE_ROWS_MOVED_BY_DATE";
export const WORKSPACE_ROWS_MOVED_BY_DATE_IS_LOADING = "WORKSPACE_ROWS_MOVED_BY_DATE_IS_LOADING";
export const WORKSPACE_DATA_MOVED_BY_DATE = "WORKSPACE_DATA_MOVED_BY_DATE";
export const WORKSPACE_DATA_MOVED_BY_DATE_IS_LOADING = "WORKSPACE_DATA_MOVED_BY_DATE_IS_LOADING";
export const WORKSPACE_REVERSE_ROWS_MOVED_BY_DATE = "WORKSPACE_REVERSE_ROWS_MOVED_BY_DATE";
export const WORKSPACE_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING = "WORKSPACE_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING";

// Custom Roles
export const CUSTOM_ROLES = "CUSTOM_ROLES";
export const CUSTOM_ROLES_IS_LOADING = "CUSTOM_ROLES_IS_LOADING";

export const CREDIT_USAGE = "CREDIT_USAGE"
export const CREDIT_USAGE_IS_LOADING="CREDIT_USAGE_IS_LOADING"