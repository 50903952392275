import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getToken, getUser } from "services/Api/api";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { CircularProgress, Grid } from "@mui/material";
const AccountHOC = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const currentPath = location.pathname + location.search;

    let users = useSelector((state) => state.users);
    useEffect(async () => {
        let subscriptionStatus = users?.user?.subscription_status;
        console.log(users.user);
        if (!users.user) {
            setLoading(true);
            const user = await getUser();
            console.log(user);
            setLoading(false);
            dispatch({ type: "SET_USER", payload: user.data.data });
            subscriptionStatus = user.data.data.subscription_status;
        }
        console.log(subscriptionStatus);

        if (subscriptionStatus === "zoho_customer_created") {
            navigate("/settings?tab=subscription_management&subTab=0&open_plan=true")
        } else if (subscriptionStatus === "trial_expired") {
            if (currentPath === "/settings?tab=subscription_management&subTab=0&open_plan=true") {
                return
            }
            navigate("/subscription/trial_over");
        } else if (subscriptionStatus === "cancelled" || subscriptionStatus === "expired") {
            navigate("/subscription/cancelled");
        } else if (subscriptionStatus === "unpaid") {
            navigate("/subscription/unpaid");
        } else if (
            subscriptionStatus !== "live" &&
            subscriptionStatus !== "trial" &&
            subscriptionStatus !== "non_renewing" &&
            subscriptionStatus !== "dunning"
        ) {
            console.log(users);
            navigate("/404");
        }
    }, []);
    if (loading) {
        console.log(loading);
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
                <CircularProgress />
            </Grid>
        );
    }
    return props.children;
};

export default AccountHOC;
