import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";
import UserTransformer from "../DataTransformations/UserTransformation";
class UserEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `users`;
        this.transformer = new UserTransformer();
    }
    getAll() {
        return axios.get(`${this.resourceEndpoint}`);
    }
    getOverallStats(data) {
        return axios.get(`/overall_stats${data.apiData.data}`);
    }
    getOverAllRunStats(data) {
        return axios.get(`/overall_run_stats${data.apiData.data}`);
    }
    getRowsMovedByDate(data) {
        return axios.get(`/datewise-stats/rows-moved${data.apiData.data}`);
    }
    getDataMovedByDate(data) {
        return axios.get(`/datewise-stats/data-moved${data.apiData.data}`);
    }
    getAllCredentials(data) {
        return axios.get(`/datasources/credentials`);
    }
    getUser() {
        return axios.get("/me");
    }
    getCreditUsage(data) {
        return axios.post('accounts/credits_usage', data.apiData.data)
    }
    deleteUser(data) {
        return axios.delete(`/users/${data.apiData.user_id}`);
    }
    updateAccountSubscriptionStatus(data) {
        return axios.post("/account/update_status", data.apiData.data);
    }
    getDataUsage(data) {
        console.log(data);
        return axios.get(`/account/data_usage?from_date=${data.apiData.from_date}`);
    }

    getDateWiseCombinedData(data) {
        console.log(data);
        return axios.post("/account/date_wise_data", data.apiData);
    }
    getReverseRowsMovedByDate(data) {
        return axios.get(`/datewise-stats/reverse-rows-moved${data.apiData.data}`);
    }
    getAllBanners(data) {
        return axios.get("/banners");
    }
    addUserDetails(data) {
        return axios.post("/users/details", data.apiData.data);
    }
    addUser(data) {
        return axios.post(`users/send_link`, data.apiData.data);
    }
    userUpdate(data) {
        return axios.post(`/users/update_information`, data.apiData.data);
    }
    getUserApiKeys(data) {
        return axios.get(`/users/api_key`);
    }
    createUserApiKey(data) {
        return axios.post(`/users/api_key`);
    }
    generateApiKey() {
        return axios.post("/users/api_key");
    }
    getApiKeys() {
        return axios.get(`/users/api_key`);
    }
    changeStatusOfApiKey(data) {
        const body = {
            status: data.apiData.new_status,
        };
        return axios.put(`/users/api_key/${data.apiData.id}`, body);
    }
    getInvoiceData() {
        return axios.get("/subscription/overdue_invoice");
    }
    getCustomRoles() {
        return axios.get("/role/custom_role");
    }
    getAllPermissions() {
        return axios.get("/custom_role/permissions/all");
    }
    addCustomRole(data) {
        return axios.post("/role/custom_role", data.apiData.data);
    }
    getOneCustomRolePermission(data) {
        return axios.get(`/role/custom_role/${data.apiData.role_id}/permissions`);
    }
    editCustomRole(data) {
        return axios.post(`/role/custom_role/${data.apiData.role_id}/edit`, data.apiData.data);
    }
    deleteCustomRole(data) {
        return axios.delete(`/role/custom_role/${data.apiData.role_id}`);
    }
}

export default UserEndpoints;
