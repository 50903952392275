import React from "react";
import Main from "../components/layout/Main";
import CallBack from "../components/Callback";
import UserInput from "components/Auth/Register/Common/UserInput";

const AskDC = React.lazy(() => import("components/AskDC/Collections"));
const CreateCollection = React.lazy(() => import("components/AskDC/Collections/CreateCollection"));
const EditCollection = React.lazy(() => import("components/AskDC/Collections/EditCollection"));
const Playgound = React.lazy(() => import("components/AskDC/Playground"));

const EditDbtTransformation = React.lazy(() => import("components/Transformations/DBT/EditDbtTransfoemation"));
const DataWarehouse = React.lazy(() => import("../components/DataWarehouse"));
const EditDataWarehouse = React.lazy(() => import("../components/DataWarehouse/EditWareHouse"));
const AddWarehouse = React.lazy(() => import("../components/DataWarehouse/AddWarehouse"));
const Settings = React.lazy(() => import("../components/Settings"));
const DataSource = React.lazy(() => import("../components/DataSource"));
// const NewSubscription = React.lazy(() => import("components/Subscription/NewSubscriptionNewDesign/index"));
const UpdateAccountCallback = React.lazy(() => import("components/Subscription/UpdateAccountCallback/index"));
const RenewSubscription = React.lazy(() => import("components/Subscription/RenewSubscription/index"));
const UpgradeSubscription = React.lazy(() => import("components/Subscription/UpgradeSubscription/index.js"));
const TrialOver = React.lazy(() => import("components/Subscription/TrialOver.js"));
const SubscriptionCancelled = React.lazy(() => import("components/Subscription/SubscriptionCancelled.js"));
const UnpaidSubscription = React.lazy(() => import("components/Subscription/UnpaidSubscription"));
const Dashboard = React.lazy(() => import("../components/Dashboard"));
const Login = React.lazy(() => import("../components/Auth/Login/index2"));
const Register = React.lazy(() => import("../components/Auth/Register/index2"));
const DataSourceDetail = React.lazy(() => import("../components/DataSource/DataSourceDetail"));
const Transform = React.lazy(() => import("../components/Transformations/SQL"));
const EditTransformation = React.lazy(() => import("../components/Transformations/SQL/TransformationsEdit"));
const EditReport = React.lazy(() => import("../components/DataSource/DataSourceDetail/EditReport"));
const HomePage = React.lazy(() => import("../components/HomePage"));
const AddTransformation = React.lazy(() => import("../components/Transformations/SQL/AddTransformation"));
const CreateReport = React.lazy(() => import("../components/DataSource/DataSourceDetail/CreateReport"));
const CreateTemplate = React.lazy(() => import("../components/DataSource/DataSourceDetail/AddTemplate"));
const DataExplorerComponent = React.lazy(() => import("../components/DataSource/DataSourceDetail/EditReport/DataPreview"));
const ReverseConnector = React.lazy(() => import("../components/ReverseETL/DataDestinations"));
const ReverseConnectorDetail = React.lazy(() => import("../components/ReverseETL/DataDestinations/ReverseConnectorDetails"));
const AddSync = React.lazy(() => import("../components/ReverseETL/DataDestinations/ReverseConnectorDetails/AddSync"));

const EditSync = React.lazy(() => import("../components/ReverseETL/DataDestinations/ReverseConnectorDetails/EditSync"));

const OAuth = React.lazy(() => import("../components/OAuth"));

const OAuthCallback = React.lazy(() => import("../components/OAuth/Callback"));

const DataModels = React.lazy(() => import("../components/ReverseETL/DataModels"));

const AddDataModel = React.lazy(() => import("../components/ReverseETL/DataModels/AddDataModel"));

const EditDataModel = React.lazy(() => import("../components/ReverseETL/DataModels/DataModelEdit"));

const Segments = React.lazy(() => import("../components/ReverseETL/Segments"));

const AddSegments = React.lazy(() => import("../components/ReverseETL/Segments/AddSegments"));

const EditSegments = React.lazy(() => import("../components/ReverseETL/Segments/EditSegment"));
const ChangePassword = React.lazy(() => import("../components/Auth/ChangePassword"));
const ResetPassword = React.lazy(() => import("../components/Auth/ResetPassword"));

const ForgotPassword = React.lazy(() => import("../components/Auth/ForgotPassword"));
const AddVerifiedUser = React.lazy(() => import("../components/Auth/AddVerifiedUser"));
const BigqueryValidation = React.lazy(() => import("components/Validations/WarehouseValidation"));
const Transfomations = React.lazy(() => import("components/Transformations"));
const DBTTransfomations = React.lazy(() => import("components/Transformations/DBT"));
const AddDBTTransfomation = React.lazy(() => import("components/Transformations/DBT/AddDbtTransformation"));
const DBTOauthNameAndProvider = React.lazy(() => import("components/Transformations/DBT/Oauth/NameAndProvider"));
const DBTOauthCallback = React.lazy(() => import("components/Transformations/DBT/Oauth/Callback.js"));
const DBTCloudCredentials = React.lazy(() => import("components/Transformations/DBTCloud/Credentials/NameAndAPIKey"));
const EditDbtCloudTransformation = React.lazy(() => import("components/Transformations/DBTCloud/EditDbtCloudTransformation"));

const AddDBTCloudTransformations = React.lazy(() => import("components/Transformations/DBTCloud/AddDbtCloudTransformation"));

const ProductAnnouncementPage = React.lazy(() => import("components/ProductAnnouncement/ProductAnnouncementPage"));
const Orcehstration = React.lazy(() => import("components/Orchestration"));
const AddOrchestration = React.lazy(() => import("components/Orchestration/AddOrchestration"));
const EditOrchestration = React.lazy(() => import("components/Orchestration/EditOrchestration"));

const DataExplorer = () => (
    <div style={{ padding: 20 }}>
        <DataExplorerComponent showTabs={true} />
    </div>
);

const ChartPage = React.lazy(() => import("components/AskDC/Playground/ChartPage"));
const GridPage = React.lazy(() => import("components/AskDC/Playground/GridPage"));

const routes = [
    {
        layout: null,
        protected: false,
        title: "Login",
        path: "/login",
        comp: Login,
        permissions: [],
        showTicketComponent: false,
    },

    {
        layout: Main,
        protected: true,
        path: "/change_password",
        comp: ChangePassword,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: false,
        path: "/reset_password",
        comp: ResetPassword,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: false,
        path: "/user_verifed/add",
        comp: AddVerifiedUser,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: false,
        path: "/forgot_password",
        comp: ForgotPassword,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: true,
        path: "/subscription/unpaid",
        comp: UnpaidSubscription,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: true,
        path: "/subscription/cancelled",
        comp: SubscriptionCancelled,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: true,
        path: "/subscription/trial_over",
        comp: TrialOver,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: true,
        path: "/subscription/updateAccount/:subscription_id",
        comp: UpdateAccountCallback,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },

    {
        layout: null,
        protected: true,
        path: "/subscription/renew",
        comp: RenewSubscription,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: true,
        path: "/subscription/upgrade",
        comp: UpgradeSubscription,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: false,
        path: "/register",
        title: "Register",
        comp: Register,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: false,
    },
    {
        layout: Main,
        protected: true,
        title: "Dashboard",
        path: "/dashboard",
        comp: Dashboard,
        permissions: ["dashboard.view"],
        features: [],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Data Sources",
        path: "/datasource",
        comp: DataSource,
        permissions: [],
        features: ["DATAPIPELINE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Data Warehouses",
        path: "/datawarehouse",
        comp: DataWarehouse,
        permissions: ["datawarehouse.view", "datawarehouse.create", "datawarehouse.delete", "datawarehouse.edit"],
        features: ["DATAWAREHOUSE", "DC_MANAGED_WAREHOUSE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Data Warehouses",
        path: "/datawarehouse/add",
        comp: AddWarehouse,
        permissions: ["datawarehouse.create"],
        features: ["DATAWAREHOUSE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        path: "/settings",
        title: "Settings",
        comp: Settings,
        permissions: ["settings.view"],
        features: [],
        checkAccount: true,
        showTicketComponent: true,
        isAccountPerm: true,
    },
    {
        layout: Main,
        protected: true,
        path: "/orchestrations",
        title: "Orchestrations",
        comp: Orcehstration,
        permissions: ["orchestration.view"],
        features: ["ORCHESTRATION"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        path: "/orchestrations/add",
        title: "Add Orchestrations",
        comp: AddOrchestration,
        permissions: ["orchestration.create"],
        features: ["ORCHESTRATION"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        path: "/orchestrations/:orchestrationId",
        title: "Edit Orchestrations",
        comp: EditOrchestration,
        permissions: ["orchestration.edit"],
        features: ["ORCHESTRATION"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Data Sources",
        path: "/datasource/:dataSourceSlug",
        comp: DataSourceDetail,
        permissions: ["report.edit", "report.view"],
        features: ["DATAPIPELINE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Transformations",
        path: "/transformations",
        comp: Transfomations,
        permissions: ["sql_transformation.view"],
        features: ["TRANSFORMATION"],
        checkAccount: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Transformations",
        path: "/transformations/sql",
        comp: Transform,
        permissions: [
            "sql_transformation.edit",
            "sql_transformation.create",
            "sql_transformation.delete",
            "sql_transformation.run",
            "sql_transformation.view",
        ],
        features: ["TRANSFORMATION"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Transformations",
        path: "/transformations/sql/edit/:transformationId",
        comp: EditTransformation,
        permissions: ["sql_transformation.edit"],
        features: ["TRANSFORMATION"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "DBT Transformations",
        path: "/transformations/dbt",
        comp: DBTTransfomations,
        permissions: [
            "dbt_transformation.edit",
            "dbt_transformation.create",
            "dbt_transformation.delete",
            "dbt_transformation.run",
            "dbt_transformation.view",
        ],
        features: ["DBT"],
        checkAccount: true,
    },
    {
        layout: Main,
        protected: true,
        title: "DBT Transformations",
        path: "/transformations/dbt/add",
        comp: AddDBTTransfomation,
        permissions: ["dbt_transformation.create"],
        features: ["DBT"],
        checkAccount: true,
    },
    {
        layout: Main,
        protected: true,
        title: "DBT Transformations",
        path: "/transformations/dbt/edit/:dbtTransformationId",
        comp: EditDbtTransformation,
        permissions: ["dbt_transformation.edit"],
        features: ["DBT"],
        checkAccount: true,
    },
    {
        layout: null,
        protected: true,
        title: "DBT Transformations",
        path: "/oauth/dbt",
        comp: DBTOauthNameAndProvider,
        permissions: ["dbt_transformation.view"],
        features: ["DBT"],
        checkAccount: true,
    },
    {
        layout: null,
        protected: true,
        title: "DBT Transformations",
        path: "/oauth/dbt/:cred_id",
        comp: DBTOauthNameAndProvider,
        permissions: ["dbt_transformation.view"],
        features: ["DBT"],
        checkAccount: true,
    },
    {
        layout: null,
        protected: true,
        title: "DBT Transformations",
        path: "/oauth/dbt/:provider/callback",
        comp: DBTOauthCallback,
        permissions: [],
        features: ["DBT"],
        checkAccount: true,
    },
    {
        layout: Main,
        protected: true,
        title: "DBT Cloud Transformations",
        path: "/transformations/dbt-cloud/add",
        comp: AddDBTCloudTransformations,
        permissions: ["dbt_transformation.create"],
        features: ["DBT"],
        checkAccount: true,
    },
    {
        layout: Main,
        protected: true,
        title: "DBT Cloud Transformations",
        path: "/transformations/dbt-cloud/edit/:dbtCloudTransformationId",
        comp: EditDbtCloudTransformation,
        permissions: ["dbt_transformation.edit"],
        features: ["DBT"],
        checkAccount: true,
    },
    {
        layout: null,
        protected: true,
        path: "transformations/dbt-cloud/creds",
        comp: DBTCloudCredentials,
        permissions: ["dbt_transformation.view"],
        features: ["DBT"],
        checkAccount: true,
    },
    {
        layout: null,
        protected: true,
        path: "transformations/dbt-cloud/creds/:dbtCredId",
        comp: DBTCloudCredentials,
        permissions: ["dbt_transformation.view"],
        features: ["DBT"],
        checkAccount: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Data Sources",
        path: "/datasource/:dataSourceSlug/detail/editreport/:reportId",
        comp: EditReport,
        permissions: ["report.edit"],
        features: ["DATAPIPELINE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Home",
        path: "/",
        comp: HomePage,
        permissions: ["report.view", "sync.view"],
        features: [],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: false,
        title: "Callback",
        path: "/callback",
        comp: CallBack,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Data Warehouses",
        path: "/datawarehouse/:dataWareHouseId",
        comp: EditDataWarehouse,
        permissions: ["datawarehouse.edit"],
        features: ["DATAWAREHOUSE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Transformation",
        path: "/transformations/add",
        comp: AddTransformation,
        permissions: ["sql_transformation.create"],
        features: ["TRANSFORMATION"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Data Sources",
        path: "/datasource/:dataSourceSlug/report/new",
        comp: CreateReport,
        permissions: ["report.create"],
        features: ["DATAPIPELINE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Data Explorer",
        path: "/data-explorer",
        comp: DataExplorer,
        permissions: ["report.create", "report.edit"],
        features: ["DATAPIPELINE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Destinations",
        path: "/reverse-connectors",
        comp: ReverseConnector,
        permissions: ["sync.view"],
        features: ["REVERSE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Destinations",
        path: "/reverse-connectors/:reverseConnectorSlug",
        comp: ReverseConnectorDetail,
        permissions: ["sync.view"],
        features: ["REVERSE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Destinations",
        path: "/reverse-connectors/:reverseConnectorSlug/sync/new",
        comp: AddSync,
        permissions: ["sync.create"],
        features: ["REVERSE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Destinations",
        path: "/reverse-connectors/:reverseConnectorSlug/sync/:configuredSyncId",
        comp: EditSync,
        permissions: ["sync.edit"],
        features: ["REVERSE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: false,
        title: "Data Explorer",
        path: "/oauth/partners",
        comp: OAuth,
        permissions: ["datawarehouse.view"],
        features: ["DATAPIPELINE"],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: false,
        title: "Data Explorer",
        path: "/oauth/generic/callback",
        comp: OAuthCallback,
        permissions: [],
        features: ["DATAPIPELINE"],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: false,
        title: "Confirm Link",
        path: "/confirm_link",
        comp: UserInput,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Data Model",
        path: "/data-models",
        comp: DataModels,
        permissions: ["sync.view"],
        features: ["REVERSE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Data Models",
        path: "/data-models/edit/:dataModelId",
        comp: EditDataModel,
        permissions: ["sync.edit"],
        features: ["REVERSE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Data Models",
        path: "/data-models/add",
        comp: AddDataModel,
        permissions: ["sync.create"],
        features: ["REVERSE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Segments",
        path: "/segments",
        comp: Segments,
        permissions: ["sync.view"],
        features: ["REVERSE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Add Segment",
        path: "/segments/add",
        comp: AddSegments,
        permissions: ["sync.create"],
        features: ["REVERSE"],
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Edit Segment",
        path: "/segments/edit/:segmentId",
        comp: EditSegments,
        permissions: ["sync.edit"],
        features: ["REVERSE"],
        showTicketComponent: true,
    },
    {
        layout: null,
        protected: false,
        title: "Server Validation",
        path: "/server-validations/:validation_type/:validation_subtype",
        comp: BigqueryValidation,
        permissions: [],
        features: [],
        checkAccount: false,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Product Updates",
        path: "/product-updates",
        comp: ProductAnnouncementPage,
        permissions: [],
        features: [],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Add Template",
        path: "/datasource/:dataSourceSlug/template/new",
        comp: CreateTemplate,
        permissions: ["template.create"],
        features: ["DATAPIPELINE"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Ask Neo",
        path: "/ask-neo/collections",
        comp: AskDC,
        permissions: ["collection.view"],
        features: [],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Create Collection",
        path: "/ask-neo/collections/add",
        comp: CreateCollection,
        permissions: ["collection.create"],
        features: ["ASK_DC"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Edit Collection",
        path: "/ask-neo/collections/:collectionId/edit",
        comp: EditCollection,
        permissions: ["collection.edit"],
        features: ["ASK_DC"],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Playground",
        path: "/ask-neo/playground",
        comp: Playgound,
        permissions: ["thread.view"],
        features: [],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Chart Page",
        path: "/ask-neo/collection/:collection_id/questions/:question_id/responses/:response_id/charts/:chart_index/",
        comp: ChartPage,
        permissions: [],
        features: [],
        checkAccount: true,
        showTicketComponent: true,
    },
    {
        layout: Main,
        protected: true,
        title: "Grid Page",
        path: "/ask-neo/questions/:question_id/responses/:response_id/grid/:grid_index",
        comp: GridPage,
        permissions: [],
        features: [],
        checkAccount: true,
        showTicketComponent: true,
    },
];
export default routes;
