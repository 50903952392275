import React from "react";
import ApiProvider from "services/Api/ApiProvider/ApiProvider";
import SuccessSnackbar from "utils/successSnackbar";
import ErrorHandler from "services/Api/HttpErrorHandler/HttpErrorHandler";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useApi = (data) => {
    const [response, setResponse] = React.useState(data?.initialValue === undefined ? null : data?.initialValue);
    const [errors, setErors] = React.useState(null);
    const [errorCode, setErrorCode] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const makeRequest = async (data) => {
        try {
            const ApiService = new ApiProvider(data);
            if (data.setLoader === true || data.setLoader === undefined) {
                setIsLoading(true);
            }
            const result = await ApiService.makeRequest();

            setResponse(result);
            if (typeof data?.successToast !== typeof undefined) {
                SuccessSnackbar(dispatch, data?.successToast);
            }
            if (data.setLoader === true || data.setLoader === undefined) {
                setIsLoading(false);
            }
            if (data.returnResult === true) {
                return result;
            }
        } catch (e) {
            setErors(e?.response);
            setErrorCode(e?.response?.status);
            const errorHandler = new ErrorHandler(e, navigate);
            if (data?.errorToast !== undefined) {
                HttpErrorHandler(dispatch, data?.errorToast);
            } else if (errorHandler.getErrorMessage() !== undefined) {
                HttpErrorHandler(dispatch, errorHandler.getErrorMessage());
            }

            if (data.setLoader === true || data.setLoader === undefined) {
                setIsLoading(false);
            }
            if (data.throwError) {
                throw e;
            }
            if (data.returnResult) {
                return {
                    status_code: e?.response?.status,
                    data: e?.response,
                };
            }
        }
    };
    React.useEffect(() => {
        if (data !== undefined) {
            makeRequest(data);
        }
    }, [data]);

    return [response, isLoading, makeRequest, setResponse, errors, errorCode];
};

export default useApi;
