import axios from "config/axios";
import reverseAxios from "config/reverseETLaxios";
import ConfigureReportTransormation from "../DataTransformations/ConfigureReportTransformation";
import BaseEndpointsClass from "./BaseEndpointsClass";

class ConfiguredReportEndpoints extends BaseEndpointsClass {
    constructor(data) {
        super();
        this.resourceEndpoint = `datasources/${data.apiData?.datasourceSlug}/configured_reports`;
        this.transformer = new ConfigureReportTransormation();
    }
    getOne(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.reportId}`);
    }
    runReport(data) {
        return axios.post(
            `${this.resourceEndpoint}/${data.apiData.configuredReportId}/manual_run`,
            data.apiData.data ? data.apiData.data : {}
        );
    }
    changeStatus(data) {
        return axios.patch(`${this.resourceEndpoint}/${data.apiData.configuredReportId}/status`, data.apiData.data);
    }
    deleteReport(data) {
        return axios.delete(`${this.resourceEndpoint}/${data.apiData.configuredReportId}`);
    }
    getReportRunLogGraph(data) {
        return axios.get(`/configured_reports/${data.apiData.reportId}/run/logs/graph`);
    }
    getTableSchema(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.reportId}/table-schema`);
    }
    getConfiguredReportLogs(data) {
        return axios.get(`/datasources/${data.apiData.data.datasource_slug}/logs${data.apiData.data.queryString}`);
    }
    getRecentActivities(data) {
        return axios.get(`/recent-activities?page=${data.apiData.page}`);
    }
    getReportEditLogs(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.reportId}/changelog`);
    }
    updateCredentials(data) {
        return axios.patch(`${this.resourceEndpoint}/${data.apiData.reportId}`, data.apiData.data);
    }
    getTransformationRunLogs(data) {
        if (data.apiData.isReverse) return reverseAxios.get(`${data.apiData.url + data.apiData.queryParameter}`);
        return axios.get(`${data.apiData.url + data.apiData.queryParameter}`);
    }
    getReportTableRelationship(data) {
        return axios.get(`/configured_reports/${data.apiData.configuredReportId}/table-relations`);
    }
}

export default ConfiguredReportEndpoints;
